import moment from 'moment';
import {take, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {efServiceAssignmentTypes, efAdditionalServiceRequestedTypes, apsServiceTypes} from '@ace-de/eua-entity-types';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import updateServiceAssignmentSubStatus from '../../service-assignments/sagas/updateServiceAssignmentSubStatus';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as sarcActionTypes from '../sarcActionTypes';
import calculateRentalCarPrice from '../calculateRentalCarPrice';
import calculateAdditionalServicePrice from '../calculateAdditionalServicePrice';

const calculateNumberOfDays = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
    const areDatesValid = moment(startDate).isValid() && moment(endDate).isValid()
        && moment(endDate).isAfter(moment(startDate));
    return areDatesValid
        ? moment(endDate).diff(moment(startDate), 'days')
        : 0;
};

const calculateCostCoverageSum = (serviceAssignment, numberOfDays, budgetPerDay, totalBudget, maxDuration) => {
    const roundToAtMost2Decimals = num => {
        const m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    };

    const {serviceCase} = serviceAssignment;
    const isDamageLocationInGermany = serviceCase.damage?.location?.countryCode === 'DEU';

    if (numberOfDays === 0) return 0;
    if (isDamageLocationInGermany) {
        const budget = serviceAssignment.budgetPerDay || budgetPerDay;
        if (numberOfDays >= 1 && numberOfDays <= maxDuration) {
            const total = parseFloat(budget) * numberOfDays;
            return roundToAtMost2Decimals(total);
        } else if (numberOfDays > maxDuration) {
            const total = parseFloat(budget) * (maxDuration);
            return roundToAtMost2Decimals(total);
        }
    }
    return totalBudget;
};

/**
 * SARC extend dropOff date flow (uses modal)
 */
const extendSARCDropOffDateFlow = function* extendSARCDropOffDateFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        yield take(sarcActionTypes.INITIATE_SARC_EXTEND_DROPOFF_DATE_FLOW);

        yield* openModal(modalIds.SARC_EXTEND_DROPOFF_DATE);

        const chosenModalOption = yield take([
            sarcActionTypes.CONFIRM_SARC_EXTEND_DROPOFF_DATE,
            sarcActionTypes.DECLINE_SARC_EXTEND_DROPOFF_DATE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === sarcActionTypes.CONFIRM_SARC_EXTEND_DROPOFF_DATE) {
            const {serviceCaseId, serviceAssignmentLineNo, serviceAssignmentSubStatusData} = chosenModalOption.payload;

            const updateServiceAssignmentSubStatusResponseAction = yield* updateServiceAssignmentSubStatus({
                caller: sarcActionTypes.CONFIRM_SARC_EXTEND_DROPOFF_DATE,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentSubStatusData,
            });

            if (!updateServiceAssignmentSubStatusResponseAction.error) {
                const {response} = updateServiceAssignmentSubStatusResponseAction.payload;
                const {serviceAssignmentDTO} = response;
                const {serviceAssignmentBudgetMaxDurations, serviceAssignmentBudgets} = yield select(state => (
                    state.serviceAssignment
                ));

                const numberOfDays = calculateNumberOfDays(
                    serviceAssignmentDTO.pickupDate,
                    serviceAssignmentSubStatusData.extendedDate,
                );
                const rentalBudget = calculateCostCoverageSum(
                    serviceAssignmentDTO,
                    numberOfDays,
                    serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__DOMESTIC_DAILY]?.value,
                    serviceAssignmentBudgets?.[apsServiceTypes.RENTAL_CAR__ABROAD_MAX]?.value,
                    serviceAssignmentBudgetMaxDurations?.[apsServiceTypes.RENTAL_CAR__DOMESTIC_DAILY]?.value,
                );
                let rentalCarCost = 0;
                const additionalServiceCosts = {
                    [efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE]: {
                        calculatedCost: 0,
                    },
                    [efAdditionalServiceRequestedTypes.WINTER_TIRES]: {
                        calculatedCost: 0,
                    },
                    [efAdditionalServiceRequestedTypes.AUTOMATIC]: {
                        calculatedCost: 0,
                    },
                    [efAdditionalServiceRequestedTypes.TRAILER]: {
                        calculatedCost: 0,
                    },
                };

                if (serviceAssignmentDTO?.sippCode && serviceAssignmentDTO?.acePartner?.id && numberOfDays) {
                    yield fork(
                        fetchRequest,
                        sarcActionTypes.FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST,
                        partnerManagementService.getServiceProviderRentalCarPricing,
                        {serviceProviderId: serviceAssignmentDTO.acePartner.id},
                    );

                    const responseAction = yield take([
                        sarcActionTypes.FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_SUCCEEDED,
                        sarcActionTypes.FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_FAILED,
                    ]);

                    if (responseAction.error) return;

                    const {response} = responseAction.payload;
                    const {rentalCarPricingDTOs} = response;

                    const rentalCarPricing = rentalCarPricingDTOs
                        .find(pricingDTO => pricingDTO.sippCode === serviceAssignmentDTO?.sippCode);

                    if (rentalCarPricing) {
                        rentalCarCost = calculateRentalCarPrice(rentalCarPricing, numberOfDays, true);
                    }

                    if (serviceAssignmentDTO.additionalServiceRequested) {
                        const additionalServicesPricing = rentalCarPricingDTOs.filter(pricingDTO => {
                            return pricingDTO.additionalService;
                        });

                        Object.values(serviceAssignmentDTO.additionalServiceRequested)
                            .forEach(additionalServiceRequested => {
                                const additionalServicePricing = additionalServicesPricing.find(pricingDTO => {
                                    return pricingDTO.additionalService === additionalServiceRequested;
                                });

                                if (additionalServicePricing) {
                                    /* eslint-disable-next-line max-len */
                                    additionalServiceCosts[additionalServiceRequested].calculatedCost = calculateAdditionalServicePrice(
                                        additionalServicePricing,
                                        numberOfDays,
                                    );
                                }
                            });
                    }
                }

                const serviceCost = additionalServiceCosts[efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE];
                const winterTiresCost = additionalServiceCosts[efAdditionalServiceRequestedTypes.WINTER_TIRES];
                const otherCost = additionalServiceCosts[efAdditionalServiceRequestedTypes.TRAILER].calculatedCost
                    + additionalServiceCosts[efAdditionalServiceRequestedTypes.AUTOMATIC].calculatedCost;

                const serviceAssignmentData = {
                    numberOfDays,
                    budgetPerDay: rentalBudget / numberOfDays,
                    costs: {
                        rental: rentalCarCost,
                        service: serviceCost.calculatedCost,
                        tires: winterTiresCost.calculatedCost,
                        other: otherCost,
                    },
                    budget: {
                        rental: rentalBudget,
                        service: serviceCost.calculatedCost,
                        tires: winterTiresCost.calculatedCost,
                        other: serviceAssignmentDTO.budget?.other,
                        delivery: serviceAssignmentDTO.budget?.delivery,
                    },
                };

                yield* updateServiceAssignment({
                    caller: sarcActionTypes.CONFIRM_SARC_EXTEND_DROPOFF_DATE,
                    assignmentType: efServiceAssignmentTypes.RENTAL_CAR,
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    serviceAssignmentData,
                });
            }
        }

        yield* closeModal(modalIds.SARC_EXTEND_DROPOFF_DATE);
    }
};

export default extendSARCDropOffDateFlow;
