import React, {useState, useRef, useMemo, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, Autosuggest, Option, DataRow, Divider} from '@ace-de/ui-components';
import {ButtonPrimary, Input, Checkbox, Form, TimeField, NumberInput} from '@ace-de/ui-components/form';
import {Icon, locationIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import config from '../../config';

const SCBasicDataTowingDestinationInfoPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_commissioning_tab');
    const {serviceAssignmentWithTowingDestination, initiateUpdateServiceAssignmentFinalDestination} = props;
    const {searchAssignmentFinalDestinationLocationGeolocation} = props;
    const [finalDestinationData, setFinalDestinationData] = useState(
        serviceAssignmentWithTowingDestination?.finalDestination ? {
            ...serviceAssignmentWithTowingDestination.finalDestination,
            locationName: serviceAssignmentWithTowingDestination.finalDestination?.location?.locationName || '',
        } : null,
    );
    const [isIdenticalToTowingDestination, setIsIdenticalToTowingDestination] = useState(
        !!serviceAssignmentWithTowingDestination?.finalDestination?.location?.id
        && !!serviceAssignmentWithTowingDestination?.towingDestination?.id
        && serviceAssignmentWithTowingDestination.finalDestination.location.id === serviceAssignmentWithTowingDestination.towingDestination.id, // eslint-disable-line max-len
    );
    const [newLocation, setNewLocation] = useState(
        serviceAssignmentWithTowingDestination?.finalDestination?.location || null,
    );
    const [errors, setErrors] = useState({
        location: '',
        workingHoursFrom: '',
        workingHoursTo: '',
        alternativeWorkingHoursFrom: '',
        alternativeWorkingHoursTo: '',
    });
    const currentLocationAddress = useRef('');
    const lastLocationSearchQuery = useRef('');
    const resetToDefaultStateRef = useRef(false);
    const searchFinalDestinationLocationGeolocationDebounced = useMemo(() => {
        if (typeof searchAssignmentFinalDestinationLocationGeolocation === 'function') {
            return debounce(
                searchAssignmentFinalDestinationLocationGeolocation,
                config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER,
            );
        }
        return () => null;
    }, [searchAssignmentFinalDestinationLocationGeolocation]);

    const handleLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchFinalDestinationLocationGeolocationDebounced({
                searchQueryString,
                serviceAssignmentId: `${serviceAssignmentWithTowingDestination.serviceCaseId}-${serviceAssignmentWithTowingDestination.lineNo}`,
            });
            lastLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleLocationCandidateSelect = locationCandidate => {
        currentLocationAddress.current = locationCandidate.formattedAddress;
        setNewLocation(locationCandidate);
    };

    const handleOnCheckboxChange = value => {
        resetToDefaultStateRef.current = true;
        setFinalDestinationData(value ? serviceAssignmentWithTowingDestination.towingDestination : null);
        setNewLocation(serviceAssignmentWithTowingDestination.towingDestination);
        setIsIdenticalToTowingDestination(value);
        if (!value) {
            setNewLocation(null);
            currentLocationAddress.current = '';
            lastLocationSearchQuery.current = '';
        }
    };

    const handleOnChange = formValues => {
        if (resetToDefaultStateRef.current) {
            resetToDefaultStateRef.current = false;
            return;
        }
        setFinalDestinationData(formValues);
    };

    const handleValidation = formValues => {
        let hasError = false;
        const updatedErrors = {
            location: '',
            workingHoursFrom: '',
            workingHoursTo: '',
            alternativeWorkingHoursFrom: '',
            alternativeWorkingHoursTo: '',
        };
        if (!newLocation?.id) {
            updatedErrors.location = translateTab('error_message.empty_address');
            hasError = true;
        }

        const timeRegex = /^(([0-1][0-9])|([2][0-3])):[0-5][0-9]$/;
        if (formValues.workingHoursFrom && !timeRegex.test(formValues.workingHoursFrom)) {
            updatedErrors.workingHoursFrom = translateTab('error_message.invalid_time_format');
            hasError = true;
        }

        if (formValues.workingHoursTo && !timeRegex.test(formValues.workingHoursTo)) {
            updatedErrors.workingHoursTo = translateTab('error_message.invalid_time_format');
            hasError = true;
        }

        if ((!formValues.workingHoursFrom && formValues.workingHoursTo)
            || (!formValues.workingHoursTo && formValues.workingHoursFrom)
        ) {
            updatedErrors.workingHoursFrom = translateTab('error_message.invalid_time_range');
            updatedErrors.workingHoursTo = translateTab('error_message.invalid_time_range');
            hasError = true;
        }

        // check if times are valid ('to' needs to be greater than 'from')
        if (formValues.workingHoursFrom && formValues.workingHoursTo
            && formValues.workingHoursFrom >= formValues.workingHoursTo
        ) {
            updatedErrors.workingHoursFrom = translateTab('error_message.invalid_time_range');
            updatedErrors.workingHoursTo = translateTab('error_message.invalid_time_range');
            hasError = true;
        }

        if (formValues.alternativeWorkingHoursFrom && !timeRegex.test(formValues.alternativeWorkingHoursFrom)) {
            updatedErrors.alternativeWorkingHoursFrom = translateTab('error_message.invalid_time_format');
            hasError = true;
        }

        if (formValues.alternativeWorkingHoursTo && !timeRegex.test(formValues.alternativeWorkingHoursTo)) {
            updatedErrors.alternativeWorkingHoursTo = translateTab('error_message.invalid_time_format');
            hasError = true;
        }

        if ((!formValues.alternativeWorkingHoursFrom && formValues.alternativeWorkingHoursTo)
            || (!formValues.alternativeWorkingHoursTo && formValues.alternativeWorkingHoursFrom)
        ) {
            updatedErrors.alternativeWorkingHoursFrom = translateTab('error_message.invalid_time_range');
            updatedErrors.alternativeWorkingHoursTo = translateTab('error_message.invalid_time_range');
            hasError = true;
        }

        // check if times are valid ('to' needs to be greater than 'from')
        if (formValues.alternativeWorkingHoursFrom && formValues.alternativeWorkingHoursTo
            && formValues.alternativeWorkingHoursFrom >= formValues.alternativeWorkingHoursTo
        ) {
            updatedErrors.alternativeWorkingHoursFrom = translateTab('error_message.invalid_time_range');
            updatedErrors.alternativeWorkingHoursTo = translateTab('error_message.invalid_time_range');
            hasError = true;
        }

        return {hasError, updatedErrors};
    };

    const handleOnSubmit = formValues => {
        // validate and update errors state
        const {hasError, updatedErrors} = handleValidation(formValues);
        setErrors(updatedErrors);
        if (hasError) return;

        initiateUpdateServiceAssignmentFinalDestination({
            serviceCaseId: serviceAssignmentWithTowingDestination.serviceCaseId,
            serviceAssignmentLineNo: serviceAssignmentWithTowingDestination.lineNo,
            serviceAssignmentData: {
                finalDestination: {
                    location: {
                        ...newLocation,
                        locationName: formValues.locationName,
                    },
                    phoneNo: formValues.phoneNo,
                    workingHoursFrom: formValues.workingHoursFrom,
                    workingHoursTo: formValues.workingHoursTo,
                    alternativeWorkingHoursFrom: formValues.alternativeWorkingHoursFrom,
                    alternativeWorkingHoursTo: formValues.alternativeWorkingHoursTo,
                    dailyFee: formValues.dailyFee,
                },
            },
        });
    };

    // if no towing destination don't render
    if (!serviceAssignmentWithTowingDestination?.towingDestination) return null;
    const {towingDestination} = serviceAssignmentWithTowingDestination;
    const {finalDestinationLocationSearchQuery, finalDestinationLocationCandidates} = serviceAssignmentWithTowingDestination; // eslint-disable-line max-len

    return (
        <Panel
            contentClassName={cx('global!ace-u-two-column-layout')}
        >
            <div>
                <h3
                    className={cx([
                        'global!ace-u-margin--top-16',
                        'global!ace-u-margin--bottom-24',
                        'global!ace-u-typography--color-default',
                        'global!ace-u-typography--variant-h3',
                    ])}
                >
                    {translateTab('panel_title.towing_destination')}
                </h3>
                <DataRow
                    label={translateTab('data_row_label.name')}
                    className={cx('ace-c-data-row--condensed')}
                    qaIdent="location-name"
                >
                    {towingDestination.locationName || '-'}
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.address')}
                    className={cx('ace-c-data-row--condensed')}
                    qaIdent="address"
                >
                    {[towingDestination.street, towingDestination.postCode, towingDestination.city].filter(value => (
                        !!value
                    )).join(', ') || '-'}
                </DataRow>
                <DataRow
                    label={translateTab('data_row_label.phone')}
                    className={cx('ace-c-data-row--condensed')}
                    qaIdent="phone"
                >
                    {towingDestination.phoneNo || '-'}
                </DataRow>
                <Checkbox
                    value={true}
                    isSelected={isIdenticalToTowingDestination}
                    onChange={handleOnCheckboxChange}
                    className={cx('global!ace-u-margin--top-128')}
                >
                    {translateTab('checkbox_label.apply_as_a_final_destination')}
                </Checkbox>
            </div>
            <div>
                <h3
                    className={cx([
                        'global!ace-u-margin--top-16',
                        'global!ace-u-margin--bottom-24',
                        'global!ace-u-typography--color-default',
                        'global!ace-u-typography--variant-h3',
                    ])}
                >
                    {translateTab('headline_text.final_towing_destination')}
                </h3>
                <Form name="finalDestinationForm" onChange={handleOnChange} onSubmit={handleOnSubmit}>
                    {formValues => {
                        const isFinalDestinationSaved = !!serviceAssignmentWithTowingDestination.finalDestination?.location?.id // eslint-disable-line max-len
                            && !!newLocation?.id
                            && serviceAssignmentWithTowingDestination.finalDestination.location?.id === newLocation.id;
                        const isFormTouched = serviceAssignmentWithTowingDestination.finalDestination?.location?.locationName !== formValues.locationName // eslint-disable-line max-len
                            || serviceAssignmentWithTowingDestination.finalDestination?.phoneNo !== formValues.phoneNo
                            || serviceAssignmentWithTowingDestination.finalDestination?.workingHoursFrom !== formValues.workingHoursFrom // eslint-disable-line max-len
                            || serviceAssignmentWithTowingDestination.finalDestination?.workingHoursTo !== formValues.workingHoursTo // eslint-disable-line max-len
                            || serviceAssignmentWithTowingDestination.finalDestination?.alternativeWorkingHoursFrom !== formValues.alternativeWorkingHoursFrom // eslint-disable-line max-len
                            || serviceAssignmentWithTowingDestination.finalDestination?.alternativeWorkingHoursTo !== formValues.alternativeWorkingHoursTo // eslint-disable-line max-len
                            || `${serviceAssignmentWithTowingDestination.finalDestination?.dailyFee}` !== `${formValues.dailyFee}`;
                        return (
                            <Fragment>
                                <DataRow
                                    label={translateTab('data_row_label.name')}
                                    className={cx('ace-c-data-row--condensed')}
                                    qaIdent="final-destination-location-name"
                                >
                                    <Input
                                        name="locationName"
                                        value={finalDestinationData?.locationName || ''}
                                        className={cx(['global!ace-u-full-width', 'ace-c-input--small'])}
                                    />
                                </DataRow>
                                <DataRow
                                    label={translateTab('data_row_label.address')}
                                    className={cx('ace-c-data-row--condensed')}
                                    qaIdent="final-destination-address"
                                >
                                    <Autosuggest
                                        name="locationSearchQuery"
                                        value={newLocation?.formattedAddress || ''}
                                        onChange={handleLocationSearchQueryChange}
                                        onOptionSelect={handleLocationCandidateSelect}
                                        optionValueSelector={locationCandidate => {
                                            return locationCandidate.formattedAddress;
                                        }}
                                        className={cx('global!ace-u-full-width')}
                                        placeholder={translateTab('input_placeholder.please_fill_in')}
                                        errors={errors?.location ? [errors.location] : []}
                                        isSmall={true}
                                    >
                                        {(formValues['locationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                    && finalDestinationLocationSearchQuery === lastLocationSearchQuery.current
                                            ? finalDestinationLocationCandidates
                                                .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                .map((locationCandidate, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            name={`relevant-location-candidate-${index}`}
                                                            value={locationCandidate}
                                                        >
                                                            <Icon
                                                                icon={locationIcon}
                                                                className={cx('global!ace-u-margin--right-16')}
                                                            />
                                                            {locationCandidate.formattedAddress}
                                                        </Option>
                                                    );
                                                }) : null}
                                    </Autosuggest>
                                </DataRow>
                                <DataRow
                                    label={translateTab('data_row_label.phone')}
                                    className={cx('ace-c-data-row--condensed')}
                                    qaIdent="final-destination-phone"
                                >
                                    <Input
                                        name="phoneNo"
                                        value={finalDestinationData?.phoneNo || ''}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'ace-c-input--small',
                                        ])}
                                    />
                                </DataRow>
                                <DataRow
                                    label={translateTab('data_row_label.working_hours')}
                                    className={cx('ace-c-data-row--condensed')}
                                    qaIdent="final-destination-working-hours"
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                        ])}
                                    >
                                        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--column-gap-24'])}>
                                            <div className={cx('global!ace-u-flex--grow-1')}>
                                                <TimeField
                                                    name="workingHoursFrom"
                                                    value={finalDestinationData?.workingHoursFrom || ''}
                                                    inputClassName={cx('global!ace-u-width--full')}
                                                    className={cx('ace-c-input-time--small')}
                                                    errors={errors?.workingHoursFrom ? [errors.workingHoursFrom] : []}
                                                />
                                            </div>
                                            <div className={cx('global!ace-u-flex--grow-1')}>
                                                <TimeField
                                                    name="workingHoursTo"
                                                    value={finalDestinationData?.workingHoursTo || ''}
                                                    inputClassName={cx('global!ace-u-width--full')}
                                                    className={cx('ace-c-input-time--small')}
                                                    errors={errors?.workingHoursTo ? [errors.workingHoursTo] : []}
                                                />
                                            </div>
                                        </div>
                                        <Divider className={cx('global!ace-u-margin--8-0')} />
                                        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--column-gap-24'])}>
                                            <div className={cx('global!ace-u-flex--grow-1')}>
                                                <TimeField
                                                    name="alternativeWorkingHoursFrom"
                                                    value={finalDestinationData?.alternativeWorkingHoursFrom || ''}
                                                    inputClassName={cx('global!ace-u-width--full')}
                                                    className={cx('ace-c-input-time--small')}
                                                    errors={errors?.alternativeWorkingHoursFrom
                                                        ? [errors.alternativeWorkingHoursFrom]
                                                        : []
                                                    }
                                                />
                                            </div>
                                            <div className={cx('global!ace-u-flex--grow-1')}>
                                                <TimeField
                                                    name="alternativeWorkingHoursTo"
                                                    value={finalDestinationData?.alternativeWorkingHoursTo || ''}
                                                    inputClassName={cx('global!ace-u-width--full')}
                                                    className={cx('ace-c-input-time--small')}
                                                    errors={errors?.alternativeWorkingHoursTo
                                                        ? [errors.alternativeWorkingHoursTo]
                                                        : []
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </DataRow>
                                <DataRow
                                    label={translateTab('data_row_label.daily_fee')}
                                    className={cx('ace-c-data-row--condensed')}
                                    qaIdent="final-destination-daily-fee"
                                >
                                    <NumberInput
                                        name="dailyFee"
                                        value={finalDestinationData?.dailyFee || ''}
                                        min={0}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'ace-c-number-input--small',
                                        ])}
                                    />
                                </DataRow>
                                <div
                                    className={cx([
                                        'global!ace-u-width--full',
                                        'global!ace-u-grid',
                                    ])}
                                >
                                    <div className={cx('global!ace-u-grid-column--span-5')} />
                                    <ButtonPrimary
                                        name="submitFinalDestinationButton"
                                        type="submit"
                                        className={cx([
                                            'global!ace-u-margin--top-24',
                                            'global!ace-u-width--full',
                                            'global!ace-u-grid-column--span-7',
                                        ], {
                                            'ace-c-button-primary--is-dark-positive': !!isFinalDestinationSaved && !isFormTouched,
                                        })}
                                    >
                                        {isFinalDestinationSaved && !isFormTouched
                                            ? (
                                                <Fragment>
                                                    {translateTab('button_label.accepted')}
                                                    <Icon
                                                        icon={checkmarkIcon}
                                                        className={cx(
                                                            'ace-c-icon--color-contrast',
                                                            'global!ace-u-margin--left-16',
                                                        )}
                                                    />
                                                </Fragment>
                                            ) : translateTab('button_label.accept')
                                        }
                                    </ButtonPrimary>
                                </div>
                            </Fragment>
                        );
                    }}
                </Form>
            </div>
        </Panel>
    );
};

SCBasicDataTowingDestinationInfoPanel.propTypes = {
    serviceAssignmentWithTowingDestination: PropTypes.object,
    initiateUpdateServiceAssignmentFinalDestination: PropTypes.func.isRequired,
    searchAssignmentFinalDestinationLocationGeolocation: PropTypes.func.isRequired,
};

SCBasicDataTowingDestinationInfoPanel.defaultProps = {
    serviceAssignmentWithTowingDestination: null,
};

const mapDispatchToProps = dispatch => ({
    initiateUpdateServiceAssignmentFinalDestination: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_FINAL_DESTINATION_UPDATE_FLOW,
        payload,
    }),
    searchAssignmentFinalDestinationLocationGeolocation: payload => dispatch({
        type: serviceAssignmentActionTypes.SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION,
        payload,
    }),
});

export default withRouter(connect(null, mapDispatchToProps)(SCBasicDataTowingDestinationInfoPanel));
